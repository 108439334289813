<template>
  <b-container fluid>

  <loading-spinner :isLoading.lazy="isLoading">
    <span v-if="errorMessage">
      {{ errorMessage }}
    </span>  
    <span v-else>

      <blockquote v-for="item in list"  
        class="bluesky-embed" 
        :data-bluesky-uri=item.uri 
        :data-bluesky-cid=item.cid>
        <p lang="en">{{item.record.text}}</p>
        &mdash; NASA TOLNet (<a href="https://bsky.app/profile/did:plc:dqrupxfzetfxmen6biqlrkls?ref_src=embed">@tolnet.bsky.social</a>) 
        <a :href="'https://bsky.app/profile/did:plc:dqrupxfzetfxmen6biqlrkls/post/' + item.idForThisPost + '?ref_src=embed' ">{{item.humanFriendlyDateTime}}</a>
      </blockquote>

    </span>  
  </loading-spinner>
</b-container>
</template>

<script>

import authMixins from '@/components/app/authMixins'
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { api, eventBus } from '@/init/init'

export default {

  name: 'Bluesky',

  mixins: [ authMixins ],

  components: {
    LoadingSpinner
  },

  data () {
    return {
      prefix: "blueskyfeed",
      isLoading: false, 
      errorMessage: '',
      list: [], 
    }
  },

  mounted() {
    eventBus
      .on(`${this.prefix}:isLoading`, bool => this.isLoading = bool )
      .on(`${this.prefix}:error`, data => (
                                    this.isLoading = false,
                                    this.errorMessage = "Unable to fetch the TOLNet Bluesky feed."))
      .on(`${this.prefix}:done`, returned_data => (
                                    this.list = this.prepListForDisplay(returned_data.posts),
                                    this.isLoading = false,
                                    eventBus.emit('get-loadBlueskyjs')
                                  ))
      .on('loadBlueskyjs', this.load_bluesky_js() );

    eventBus.emit('get-data', { name: this.prefix, 
                  path: '',
                  query: {'q': 'from:@tolnet.bsky.social' },
                  spinner: true} );

  },

  methods: {
     prepListForDisplay(incomingList) {
      for (let i = 0; i < incomingList.length; i++) {
        incomingList[i].idForThisPost = incomingList[i].uri.split('\/').pop();
        incomingList[i].humanFriendlyDateTime = this.reformatDate(incomingList[i].indexedAt);
      };
      return incomingList;
    },

    reformatDate(incomingDate) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: 'numeric',
        minute: 'numeric',
      };
      const date = new Date(incomingDate);
      return date.toLocaleDateString("en-US", options);
    },

    async load_bluesky_js() {
      await new Promise(resolve => setTimeout(resolve, 500));
      let blueskyScript = document.createElement('script');
      blueskyScript.setAttribute('src', 'https://embed.bsky.app/static/embed.js');
      document.head.appendChild(blueskyScript);
    },

    beforeDestroyed () {
      eventBus.off('text-search:change')
              .off('document-types:change')
              .off('sort:change')
    },
 
  },

}
</script>

<style scoped>

.card {
  text-align: center;
}

.card-text {
  padding: 10px;
}

/* fontawesome icons */
.fa-user, .fa-user-circle {
  color: steelBlue;
}

.urs-logout {
  color: steelBlue;
  display: block;
  font-size: 75%;
  margin-top: 10px;
}

</style>
