<template>
  <div class="vld-parent" >
    <loading-overlay v-if="isLoading"
      :active="true"
      :can-cancel="false"
      :is-full-page="false"
      color="#0f6cb8"
      >
      <template v-slot:after>
        <div class="load-data mx-auto">
          Loading ...
        </div>
      </template>
    </loading-overlay>
    <slot></slot>
  </div>
</template>

<script>

import LoadingOverlay from 'vue-loading-overlay'

export default {

  components: {
    LoadingOverlay
  },

  props: {
    isLoading: Boolean,
  },

}

</script>

<style>

.load-data {
  color: darkGrey;
  font-weight: bold;
  font-size: large;
}

</style>
